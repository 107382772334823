import React from 'react'
import './About.css'


function About() {
  return (
    <div>
      <h2 className='abtposter abth2' >About Us</h2>
    <div className='abtcontent'>
    <p>
        At LP FUTURE, we are driven by the vision of providing unparalleled educational guidance and support. 
        Founded by Ramanathan, an accomplished MBA graduate, our startup is built on a strong foundation of knowledge, 
        experience, and a passion for empowering individuals to achieve their educational aspirations.
    </p>
    <div className="abtcontainer">
    <div class="founderimg"></div>
      
      <div className="fndrcontent">
      <h2 className='abth2 aaa'>Our Founder</h2>  
      <p>
        Ramanathan is not just an MBA graduate; they are a visionary with a deep understanding of the ever-evolving landscape of education. 
        With a background in startup idea creation and experience in education admissions over India and abroad , Ramanathan brings a unique 
        blend of expertise to the table. Their journey through higher education and professional growth has inspired the creation of LP FUTURE.
    </p>
      </div>
    </div>
      
    
    <h2 className='abth2'>Our Mission</h2>
    <p>
    At LP FUTURE , our mission is clear: to be your trusted partner on the path to educational success. We believe that every individual 
    deserves access to quality education and personalized guidance. We are committed to providing expert consultation services that cater 
    to the unique needs of each student.
    </p>
    <h2 className='abth2'>What Sets Us Apart</h2>
    <p>
    Join us at LP FUTURE and embark on a transformative educational journey. Let's unlock your potential and make your dreams a reality together.
    </p>
    <div class="cardcontainer">
        <div class="parent">
            <div class="card">
                <div class="content-box">
                    <h1 class="card-title">Personalized Guidance</h1>
                    <p class="card-content">
                    We understand that no two individuals are alike, and neither are their educational goals. Our tailored consultation services ensure that you receive guidance specific to your aspirations.
                    </p>
                </div>
            </div>
        </div>

        <div class="parent card2">
            <div class="card ">
                <div class="content-box">
                    <h1 class="card-title">Up-to-Date Knowledge</h1>
                    <p class="card-content">
                    With our finger on the pulse of the education industry, we stay updated on the latest trends, admissions processes, 
    and opportunities, ensuring you're always one step ahead.
                    </p>
                    
                </div>
            </div>
        </div>

        <div class="parent card3">
            <div class="card">
                <div class="content-box">
                    <h1 class="card-title">Empowering Your Journey</h1>
                    <p class="card-content">
                    We're not just here to advise; we're here to empower you. Our goal is to equip you with the tools, knowledge, 
    and confidence you need to succeed.
                    </p>
                    
                </div>
                
            </div>
        </div>

        <div class="parent card4">
            <div class="card">
                <div class="content-box">
                    <h1 class="card-title">Holistic Approach</h1>
                    <p class="card-content">
                    We consider not just academic achievements but also personal growth, career goals, and life aspirations in our consultations. Education is more than just grades; it's a life-changing experience.
                    </p>
                    
                </div>
                
            </div>
        </div>
    </div>
    </div>
    </div>
  )
}


export default About