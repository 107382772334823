import React, { useState, useEffect } from "react";
import MultiSelect from "./MultiSelect";
import RadioBtn from "./RadioBtn";
import "./CourseForm.css"; 
import axios from "axios";
// import Navbar from "../Navbar/Navbar";
import { useSpring, animated } from "react-spring";
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function CourseForm() {
  const [nameU, setNameU] = useState("");
  const [mobile, setMobile] = useState("");
  const [mail, setMail] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [responseStatus, setResponseStatus] = useState(null);

  const [selectedCourseOptions, setSelectedCourseOptions] = useState([]);
  const [selectedCollegeOptions, setSelectedCollegeOptions] = useState([]);

  const nameAnimation = useSpring({ opacity: 1, from: { opacity: 0 } });
  const mobileAnimation = useSpring({ opacity: 1, from: { opacity: 0 } });
  const mailAnimation = useSpring({ opacity: 1, from: { opacity: 0 } });

  function nameHandler(event) {
    setNameU(event.target.value);
  }

  function phoneHandler(event) {
    setMobile(event.target.value);
  }

  function mailHandler(event) {
    setMail(event.target.value);
  }

  const handleRadioChange = (value) => {
    setSelectedOption(value);
  };

  const handleCourseSelectChange = (selectedOptions) => {
    setSelectedCourseOptions(selectedOptions);
  };

  const handleCollegeSelectChange = (selectedOptions) => {
    setSelectedCollegeOptions(selectedOptions);
  };

  async function submitHandler(event) {
    event.preventDefault();

     // Validation
  if (nameU.trim() === '' || mobile.trim() === '' || mail.trim() === '' || selectedOption === '' || selectedCourseOptions.length === 0 || selectedCollegeOptions.length === 0) {
    // Handle invalid form
    toast.error('Please fill in all fields', {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000, // Close the notification after 3 seconds
    });
    setResponseStatus('invalid');
    console.log('Please fill in all fields');
    return;
  }

    const Data = {
      nameU: nameU,
      mobile: mobile,
      mail: mail,
      qual: selectedOption,
      courses: selectedCourseOptions.map((option) => option.value),
      colleges: selectedCollegeOptions.map((option) => option.value),
    };

    try {
      const res = await axios.post("https://mern-web-edu-ram.onrender.com/add", {
        Data,
      });

      if (res.data === "failed") {
        console.log("Data is not correct");
        toast.error('Data is not correct');
        setResponseStatus("failed");
      } else if (res.data === "yess") {
        console.log("Data stored");
        alert('You Form Succesfully Submitted!!');
        toast.success('You Form Succesfully Submitted!!');
        setResponseStatus("success");
      }
    } catch (error) {
      
      console.log(error);
      setResponseStatus("error");
    }

    // Clear form fields and selected options
    setNameU("");
    setMobile("");
    setMail("");
    setSelectedOption("");
    setSelectedCourseOptions([]);
    setSelectedCollegeOptions([]);


    // reload page
    setTimeout(successToast, 3000);
    
    toast.success('You Form Succesfully Submitted!!');
    
    function successToast(){
      window.location.reload();
    }
  }

  useEffect(() => {
    if (responseStatus === "success") {
      // Clear selected options after a successful response
      setSelectedCourseOptions([]);
      setSelectedCollegeOptions([]);
    }
  }, [responseStatus]);

  return (
    <animated.div className="course-form-container" style={{ opacity: 1, from: { opacity: 0 } }}>
       <ToastContainer /> {/* Place ToastContainer here */}
      {/* <animated.div className="course-form-container" style={{ opacity: 1, from: { opacity: 0 } }}></animated.div> */}
      <div className="heading">
        <h2>Course Enroll Form</h2>
      </div>
      <form action="POST" className="form" onSubmit={submitHandler}>
        <div>
          <animated.label style={nameAnimation}>Name</animated.label>
          <animated.input
            type="text"
            value={nameU}
            onChange={nameHandler}
            style={nameAnimation}
          />
        </div>
        <div>
          <animated.label style={mobileAnimation}>Mobile Number</animated.label>
          <animated.input
            type="text"
            value={mobile}
            onChange={phoneHandler}
            style={mobileAnimation}
          />
        </div>
        <div>
          <animated.label style={mailAnimation}>Email</animated.label>
          <animated.input
            type="text"
            value={mail}
            onChange={mailHandler}
            style={mailAnimation}
          />
        </div>
        <div className="form-field">
          <RadioBtn
            selectedOption={selectedOption}
            onRadioChange={handleRadioChange}
          />
        </div>
        <div className="form-field">
          <label>Preferred Course</label>
          <MultiSelect
            className="multiselect"
            onCourseSelectChange={handleCourseSelectChange}
            onCollegeSelectChange={handleCollegeSelectChange}
          />
        </div>
        <button type="submit" className="enroll-button">
          Enroll
        </button>
      </form>
    </animated.div>
  );
}

export default CourseForm;