import React from 'react'
import PropTypes from 'prop-types'
import './Testimonials.css'
import { GiStairsGoal } from 'react-icons/gi';


const Testimonials = props => {
  return (
    
    <div className='Tcontent'>
         <div className='Thead'><h2>Achievements</h2></div>
    <section class="Tarticles">
        
  <article className='Tarticle'>
   
    <div class="Tarticle-wrapper">
      
      <div class="Tarticle-body">
      
        <div className='Th2'><div className='Ticon'><GiStairsGoal/></div><h2>Guidance</h2></div>
        <p>
        LP FUTURE aims to continue being a leading education and management advisor by steadfastly maintaining its commitment to personalized guidance. The primary goal is to ensure that every student receives tailored consultations that address their specific needs, goals, and aspirations. 
        </p>
        
      </div>
    </div>
  </article>
  <article>

    <div class="Tarticle-wrapper">
     
      <div class="Tarticle-body">
      <div className='Th2'><div className='Ticon'><GiStairsGoal/></div><h2>Scholarships</h2></div>
        <p>
        LP FUTURE strives to broaden the horizons for students by expanding the range of educational options available to them. The company seeks to establish partnerships with a diverse array of educational institutions, both domestic and international, to offer students an extensive menu of fields to explore.
        </p>
        
      </div>
    </div>
  </article>
  <article>

    <div class="Tarticle-wrapper">
      <div class="Tarticle-body">
      <div className='Th2'><div className='Ticon'><GiStairsGoal/></div><h2>Admissions</h2></div>
        <p>
          Remaining at the forefront of the industry, LP FUTURE sets a goal to foster innovation in the realm of education and management consultation. This involves staying abreast of the latest trends and advancements in educational technology, data analytics, and counseling methodologies. 
        </p>
        
      </div>
    </div>
  </article>
</section>
</div>
  )
}

Testimonials.propTypes = {}

export default Testimonials