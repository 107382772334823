import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useTransition, animated } from 'react-spring';
import './MultiSelect.css'; // Import your CSS file
import axios from 'axios';

const MultiSelect = ({ onCourseSelectChange, onCollegeSelectChange, responseStatus }) => {
const [allCourses, setAllCourses] = useState({});
const [data, setData] = useState({});


const url = 'https://mern-web-edu-ram.onrender.com/courses';
useEffect(() => {
  axios
  .get(url)
  .then((response) => {
    // Access all course categories
    const d = response.data;
    delete d[0]._id;
    setData(d[0]);
    setAllCourses(d[0]);
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    });
}, []);


const allValues = [];
  
  // Loop through each field of study (UG and PG)
  for (const category in data) {
    if (Object.hasOwnProperty.call(data, category)) {
      const fields = data[category];
      
      // Loop through each array and extract values
      for (const field in fields) {
        if (Object.hasOwnProperty.call(fields, field)) {
          const values = fields[field];
          allValues.push(...values);
        }
      }
    }
  }


  allValues.push('OTHERS')
  const courseCategories = allValues;

  
  console.log();

  const convertedCourses = courseCategories.map(category => ({
    value: category,
    label: category
  }));

  const colleges = [
    "Chennai", 
    "Bangalore", 
    "Coimbatore", 
    "Rajasthan",
    "Delhi",
    "Hyderabad",
    "Madurai",
    "Salem",
    "Erode",
    "Trichy", 
    "Pudhukottai",
    "Perambalur",
    "Abroad" ,
    "Other",
  ];

  const convertedColleges = colleges.map(college => ({
    value: college,
    label: college
  }));

  const [selectedCourseOptions, setSelectedCourseOptions] = useState([]);
  const [selectedCollegeOptions, setSelectedCollegeOptions] = useState([]);

  const handleCourseSelectChange = (selectedOptions) => {
    setSelectedCourseOptions(selectedOptions);
    onCourseSelectChange(selectedOptions);
  };

  const handleCollegeSelectChange = (selectedOptions) => {
    setSelectedCollegeOptions(selectedOptions);
    onCollegeSelectChange(selectedOptions);
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      transition: 'all 0.3s',
      border: '2px solid #009900',
      boxShadow: state.isFocused ? '0 0 5px #009900' : 'none',
    }),
  };

  const courseTransitions = useTransition(selectedCourseOptions, {
    from: { opacity: 0, transform: 'translateX(20px)' },
    enter: { opacity: 1, transform: 'translateX(0)' },
    leave: { opacity: 0, transform: 'translateX(20px)' },
  });

  const collegeTransitions = useTransition(selectedCollegeOptions, {
    from: { opacity: 0, transform: 'translateX(20px)' },
    enter: { opacity: 1, transform: 'translateX(0)' },
    leave: { opacity: 0, transform: 'translateX(20px)' },
  });

  useEffect(() => {
    if (responseStatus === 'success') {
      // Clear selected options after a successful response
      setSelectedCourseOptions([]);
      setSelectedCollegeOptions([]);
    }
  }, [responseStatus]);

  return (
    <div>
      <div>
        <Select
          options={convertedCourses}
          value={selectedCourseOptions}
          onChange={handleCourseSelectChange}
          isMulti
          styles={customStyles}
        />
        <div className="options-container">
          {courseTransitions((props, item) => (
            <animated.div
              style={{ ...props }}
              className="selected-option"
            >
              {item.label}
            </animated.div>
          ))}
        </div>
        <label>Preferred Locations
        </label>
      </div>
      <div>
        <Select
          options={convertedColleges}
          value={selectedCollegeOptions}
          onChange={handleCollegeSelectChange}
          isMulti
          styles={customStyles}
        />
        <div className="options-container">
          {collegeTransitions((props, item) => (
            <animated.div
              style={{ ...props }}
              className="selected-option"
            >
              {item.label}
            </animated.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MultiSelect;

/*
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import './MultiSelect.css'; // Import your CSS file

const MultiSelect = ({ onCourseSelectChange, onCollegeSelectChange, responseStatus }) => {
  const courseCategories = [
    "BE",
    "Bsc",
    "Medical",
    "AppliedScience",
    "Lawyer",
    "MCA",
    "MBA"
  ];

  const convertedCourses = courseCategories.map(category => ({
    value: category,
    label: category
  }));

  const colleges = [
    "Kongu Engineering College",
    "Sengunathar Engineering College",
    "Erode Engineering College",
    "Nandha Engineering College"
  ];

  const convertedColleges = colleges.map(college => ({
    value: college,
    label: college
  }));

  const [selectedCourseOptions, setSelectedCourseOptions] = useState([]);
  const [selectedCollegeOptions, setSelectedCollegeOptions] = useState([]);

  const handleCourseSelectChange = (selectedOptions) => {
    setSelectedCourseOptions(selectedOptions);
    onCourseSelectChange(selectedOptions);
  };

  const handleCollegeSelectChange = (selectedOptions) => {
    setSelectedCollegeOptions(selectedOptions);
    onCollegeSelectChange(selectedOptions);
  };

  // Use useEffect to reset selected options when responseStatus changes
  useEffect(() => {
    if (responseStatus === 'success') {
      setSelectedCourseOptions([]);
      setSelectedCollegeOptions([]);
    }
  }, [responseStatus]);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      transition: 'all 0.3s',
      border: '2px solid #009900',
      boxShadow: state.isFocused ? '0 0 5px #009900' : 'none',
    }),
  };

  return (
    <div>
      <div>
        <Select
          options={convertedCourses}
          value={selectedCourseOptions}
          onChange={handleCourseSelectChange}
          isMulti
          styles={customStyles}
        />
      </div>
      <div>
        <Select
          options={convertedColleges
          }
          value={selectedCollegeOptions}
          onChange={handleCollegeSelectChange}
          isMulti
          styles={customStyles}
        />
      </div>
    </div>
  );
};

export default MultiSelect;

*/