import React from 'react';
import "./Awards.css"
import award1 from "../../Assets/Media/award1.jpeg"

const Awards = () => {
  return (
    <div className='awards'>
      <img className='award1' src={award1} alt="" />
      <p className="content_descript">LP Future Educational Services is honored to announce our esteemed partnership with Sri Venkateswara College of Computer Applications and Management as the official Admission Coordinator. As collaborative stewards of education, we are dedicated to simplifying and enhancing the admission experience for students, facilitating a pathway to academic excellence at Sri Venkateswara College of Computer Applications and Management. Together, we are committed to shaping futures and fostering a vibrant learning community.</p>
    </div>
  );
};

export default Awards;
