import React from 'react';
import "./Studyabroad.css"
import { Link, Routes, Route } from "react-router-dom"
import usflag from "../../Assets/Media/USflag.png"
import ukflag from "../../Assets/Media/ukflag.png"
import canflag from "../../Assets/Media/canadaflag.png"
import franflag from "../../Assets/Media/franceflag.png"
import nzflag from "../../Assets/Media/nzflag.png"
import swflag from "../../Assets/Media/swedanflag.png"
import sgflag from "../../Assets/Media/sgflag.png"
import ireflag from "../../Assets/Media/irelandflag.png"

const StudyAbroadPage = () => {
  return (
    <div className='SA'>
      <h1>Study Abroad</h1>
      <div className='countrysec'>
        
        <div>
          <img className='countrycard' src={usflag} alt="US Flag" />
          <p>United States</p>
        </div>
        <div>
          <img className='countrycard' src={ukflag} alt="UK Flag" />
          <p>United Kingdom</p>
        </div>
        <div>
          <img className='countrycard' src={canflag} alt="Canada Flag" />
          <p>Canada</p>
        </div>
        <div>
          <img className='countrycard' src={franflag} alt="France Flag" />
          <p>France</p>
        </div>
        <div>
          <img className='countrycard' src={nzflag} alt="New Zealand Flag" />
          <p>New Zealand</p>
        </div>
        <div>
          <img className='countrycard' src={swflag} alt="Sweden Flag" />
          <p>Sweden</p>
        </div>
        <div>
          <img className='countrycard' src={sgflag} alt="Singapore Flag" />
          <p>Singapore</p>
        </div>
        <div>
          <img className='countrycard' src={ireflag} alt="Ireland Flag" />
          <p>Ireland</p>
        </div>
      </div>
      <div className='contactsec'>
        <h2>Contact Us for More Details</h2>
        <p>
          For more information about studying abroad in any of these countries,
          please contact us now.
        </p>
        
        <Link to="/Contactus" className='decoration'><button className='contactbut'>Contact Us</button></Link>
      </div>
    </div>
  );
};

export default StudyAbroadPage;
