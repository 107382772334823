import React, { useState, useEffect } from "react";
import "./CourseList.css";
import axios from "axios";

const CourseList = () => {
  const [pgCourses, setPgCourses] = useState({});
  const [allCourses, setAllCourses] = useState({});
  const [expandedRows, setExpandedRows] = useState([]);
  const [expandedRowsPg, setExpandedRowsPg] = useState([]);
  const url = "https://mern-web-edu-ram.onrender.com/courses"; // Replace with your backend API URL

  useEffect(() => {
    axios
      .get(url)
      .then((response) => {
        const data = response.data[0].UG; // Access all course categories
        setPgCourses(response.data[0].PG);
        setAllCourses(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const toggleRow = (index) => {
    if (expandedRows.includes(index)) {
      setExpandedRows(expandedRows.filter((item) => item !== index));
    } else {
      setExpandedRows([...expandedRows, index]);
    }
  };
  const toggleRowpg = (indexpg) => {
    if (expandedRowsPg.includes(indexpg)) {
      setExpandedRowsPg(expandedRowsPg.filter((itemm) => itemm !== indexpg));
    } else {
      setExpandedRowsPg([...expandedRowsPg, indexpg]);
    }
  };

  return (
    <div className="wholecourses">
      <div className="courses">
        <div className="ug">
          <div>
            <h3 className="ugtxt">UG COURSES</h3>
          </div>
          <ul className="uglist">
            {Object.entries(allCourses).map(([category, courses], index) => (
              <li className="li_items" key={index}>
                <div onClick={() => toggleRow(index)}>
                  <h1 className="cat">{category}</h1>
                </div>
                {expandedRows.includes(index) && (
                  <ul>
                    {courses.map((course, courseIndex) => (
                      <li key={courseIndex} className="cou">
                        <a href="/enroll">
                          <div className="crs">{course}</div>
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>

        <div className="pg">
          <div>
            <h3 className="pgtxt">PG COURSES</h3>
          </div>
          <ul className="pglist">
            {Object.entries(pgCourses).map(([category, courses], indexs) => (
              <li className="li_items" key={indexs}>
                <div onClick={() => toggleRowpg(indexs)}>
                  <h1 className="cat">{category}</h1>
                </div>
                {expandedRowsPg.includes(indexs) && (
                  <ul>
                    {courses.map((course, courseIndexs) => (
                      <li key={courseIndexs} className="cou">
                        <a href="/enroll">
                          <div className="crs">{course}</div>
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CourseList;
