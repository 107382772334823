import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
  const [isActive, setIsActive] = useState(false);
  const location = useLocation();

  const toggleNav = () => {
    setIsActive(!isActive);
  };

  const closeNav = () => {
    setIsActive(false);
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="navbar">
      <div className="toggle-btn">
        <button className="toggle" onClick={toggleNav}>
          &#9776;
        </button>
      </div>
      <div className={`content ${isActive ? 'active' : ''}`}>
        <Link
          to="/home"
          className={`decoration ${location.pathname === '/home' ? 'current-page' : ''} ${windowWidth <= 768 ? 'no-underline' : ''}`}
          onClick={closeNav}
        >
          Home
        </Link> 
        <Link
          to="/courses"
          className={`decoration ${location.pathname === '/courses' ? 'current-page' : ''} ${windowWidth <= 768 ? 'no-underline' : ''}`}
          onClick={closeNav}
        >
          All Courses
        </Link>
        <Link
          to="/about"
          className={`decoration ${location.pathname === '/about' ? 'current-page' : ''} ${windowWidth <= 768 ? 'no-underline' : ''}`}
          onClick={closeNav}
        >
        About Us
        </Link>
        <Link
          to="/testimonials"
          className={`decoration ${location.pathname === '/testimonials' ? 'current-page' : ''} ${windowWidth <= 768 ? 'no-underline' : ''}`}
          onClick={closeNav}
        >
        Testimonials
        </Link>
        <Link
          to="/awards"
          className={`decoration ${location.pathname === '/awards' ? 'current-page' : ''} ${windowWidth <= 768 ? 'no-underline' : ''}`}
          onClick={closeNav}
        >
          Awards
        </Link>
        <Link
          to="/contactus"
          className={`decoration ${location.pathname === '/contactus' ? 'current-page' : ''} ${windowWidth <= 768 ? 'no-underline' : ''}`}
          onClick={closeNav}
        >
        Contact Us
        </Link>
        <Link
          to="/abroad"
          className={`decoration ${location.pathname === '/abroad' ? 'current-page' : ''} ${windowWidth <= 768 ? 'no-underline' : ''}`}
          onClick={closeNav}
        >
        Study Abroad
        </Link>
       


      </div>
    </div>
  );
}

export default Navbar;
