import React from "react";
import "./Section1.css";
function Section1() {
  return (
    <div className="Section1">
      <div className="ACcontent">
        <h2 className="ACtxt">All Courses</h2>
      
      <p className="ACp">
      Explore a curated selection of courses on our platform, sourced exclusively from renowned colleges. Each course undergoes meticulous scrutiny, ensuring alignment with current industry needs. Our commitment to relevance guarantees that every recommended program optimizes students' job prospects. Elevate your educational journey with confidence, knowing that our offerings are tailored to provide you with the best opportunities for success. Unleash your potential by enrolling in courses backed by our commitment to excellence and a strategic focus on advancing your career aspirations.
      </p>
      </div>
    </div>
  );
}

export default Section1;
