import React from 'react'
import './Topgradient.css'
import { Link, Routes, Route } from "react-router-dom"






function Topgradient() {
    
    return(
    <div className='homegradient'>
        
        <div>
        <h1 className='h1'>Unlock Your <br />Academic Potential with <br /> <span>LP Future Educational Services</span></h1>
        <div className='button'>
        <Link to="/enroll"><button className='btn'>Enroll Now</button></Link>
        <Link to="/courses"><button className='btn'>View All Courses</button></Link>
        </div>
       </div>
    </div>
    );
}
export default Topgradient;
