import React from "react";
import { BrowserRouter,Routes,Route } from "react-router-dom"
import './App.css';
import Navbar from './components/Navbar/Navbar';
import Home from './components/Home/Home';
import CourseForm from "./components/CourseForm/CourseForm";
import About from "./components/About/About";
import AllCourses from "./components/AllCourses/AllCourses";
import Feedback from "./components/Testmonials/Feedback";
import Footer from "./components/Footer/Footer";
import Contactus from "./components/ContactUs/Contactus"
import Studyabroad from "./components/Studyabroad/Studyabroad"
import Hotjar from '@hotjar/browser';
import Awards from "./components/Awards/Awards";

const siteId = 3698978;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

function App() {

  return (
    
    <div id="App">
      <BrowserRouter>
      <Navbar/>

      <Routes>
        <Route exact path='/' element={<Home/>}/>
        <Route path="/home" element={<Home/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/courses" element={<AllCourses/>} /> 
        <Route path="/enroll" element={<CourseForm />} /> 
        <Route path="/Contactus" element={<Contactus />} /> 
        <Route path="/testimonials" element={<Feedback />} />
        <Route path="/abroad" element={<Studyabroad />} />
        <Route path="/awards" element={<Awards />} />
      </Routes>
      <Footer/>
      </BrowserRouter>

      
      {/* WhatsApp icon */}
      <a
        href="https://api.whatsapp.com/send?phone=918489889037"
        class="whatsapp_float float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-whatsapp whatsapp-icon"></i>
      </a>
      <a
        href="https://www.instagram.com/lpfuture_"
        class="instagram_float instagram float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-instagram instagram-icon"></i>
      </a>
      
    </div>
  );
}

export default App;