import React from "react";
import { BiLogoInstagramAlt } from "react-icons/bi";
import { BsLinkedin } from "react-icons/bs";
import { FaWhatsapp } from "react-icons/fa";
import { Link } from "react-router-dom";
import logo from "../../Assets/Media/LP logo.png"
import "./Footer.css";

const Footer = () => {
  return (
    
    <div className="footer">
      <div className="footer-left">
        <div className="address">
          <b>Address:</b>
          <br/>
          <p>53/28 Sornanthan Street,</p>
          <p>Devakottai - 630302, </p>
          <p>Sivagangai, </p>
          <p>Tamilnadu.</p>
        </div>
        <div className="email"><a href="mailto:lpfuture369@gmail.com"><b>Email:</b><br/>lpfuture369@gmail.com</a></div>
        <div className="phnum"><b>Phone:</b><br/><p>+91 8489889037 </p><p>+91 9629814983</p></div>
      </div>
      <div className="footer-center">
        <div className="flogo"><img className="lplogo" src={logo} alt="" /></div>
        <div className="socials">
          <tr>
            <td className="sicon">
            <a href="https://api.whatsapp.com/send?phone=918489889037" target="_blank" rel="noopener noreferrer"><FaWhatsapp /></a>
            </td>
            <td className="sicon">
             <a href="https://instagram.com/lpfuture_?utm_source=qr" target="_blank" rel="noopener noreferrer"><BiLogoInstagramAlt /></a> 
            </td>
            <td className="sicon">
            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer"><BsLinkedin /></a>
            </td>
          </tr>
        </div>
      </div>
      <div className="footer-right">
     
       
       
        <div>
        <Link to="/enroll" className='decoration'>Enroll Now</Link>
        </div>
        <div>
        <Link to="/contactus" className="decoration">
          Contact Us
        </Link>
          </div>
        <div>
        <Link to="/about" className="decoration">
          About Us
        </Link>
          </div>
      </div>
    </div>
  );
};

export default Footer;
