import React from "react";
import "./Contactus.css";
import Lottie from 'react-lottie';
import animationData from '../../Assets/Media/Animation - 1700901372009.json';
import animationfile from '../../Assets/Media/Animation - 1700926344898.json';
import logo from "../../Assets/Media/WhatsApp_icon.png.webp"
import logo2 from "../../Assets/Media/insta.png"
import logo3 from "../../Assets/Media/linkedin.png"
import logo4 from "../../Assets/Media/location.png"
import logo5 from "../../Assets/Media/mail.png"


function ContactForm() {
 
  const lo = {
    loop: true,
    autoplay: true,
    animationData: animationfile,
  };
  return (
    <div>
    <div className="contactus">

      <div className="concontainer">

      
        <div className="cencontent">
        
        
       
          <div className="wp ccdiv">
          <h1 className="conh1">CONTACT US</h1>
            <div className="wpicon">
           <a href="https://api.whatsapp.com/send?phone=918489889037" target="_blank" rel="noopener noreferrer"><img className="lpclogo fixed-size" src={logo} alt="Ponga da dei" /></a>
              {/* <BsWhatsapp /> */}
            </div>
            <p>Whatsapp</p>
          </div>
          <div className="in ccdiv">
            <div className="inicon">
            <a href="https://instagram.com/lpfuture_?utm_source=qr" target="_blank" rel="noopener noreferrer"><img className="lpclogo fixed-size" src={logo2} alt="Ponga da dei" /></a>
            </div>
            <p>Instagram</p>
          </div>
          <div className="li ccdiv">
            <div className="liicon">
            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer"><img className="lpclogo fixed-size" src={logo3} alt="Ponga da dei" /></a>
              {/* <AiFillLinkedin /> */}
            </div>
            {/* <p>Linkedin</p> */}
            <p> Linkedin</p>
          </div>
          <div className="ad ccdiv">
            <div className="adicon">
            <a href="https://maps.app.goo.gl/88Rt8iTTcuS7AJzV9" target="_blank" rel="noopener noreferrer"><img className="lpclogo fixed-size" src={logo4} alt="Ponga da dei" /></a>
              {/* <FaLocationDot /> */}
            </div>
            <p> 53/28, Sornanthan Street <br />Devakottai, TN, India 630302</p>
          </div>
          <div className="mail ccdiv">
            <div className="mailicon">
            <a href="mailto:lpfuture369@gmail.com" target="_blank" rel="noopener noreferrer"><img className="lpclogo fixed-size" src={logo5} alt="Ponga da dei" /></a>
              {/* <GrMail /> */}
            </div>
            <p> lpfuture369@gmail.com</p>
          </div>
          
        </div>
        
        {/* <div>
      <h1 className='conh1'>Contact Us </h1>
      </div>
      
        <div className="form-group">
          <BsWhatsapp/> <p>+91 9876543210</p>
        </div>
        <div className="form-group">
        <BsFacebook/> <p>@LPFuture</p>
        </div>
        <div className="form-group">
        <AiFillInstagram/> <p>@LPFuture</p>
        </div>
        <div className="form-group">
        <AiFillLinkedin/> <p>RamLPFuture</p>
        </div>
        <div className="form-group">
          <button className='conbutton' type="submit">Submit</button>
        </div>
        <p>
          
        </p> */}
      </div>
      <div className="contactsvg">
      <Lottie options={lo} />
      </div>
    </div>
    </div>
    
  );
}

export default ContactForm;
