import React from 'react'
import './Home.css'
import Topgradient from './Homecomponents/Topgradient';
import Cards from './Homecomponents/Cards';
import Chooseus from './Homecomponents/chooseus';
import Testimonials from './Homecomponents/Testimonials';
import HomeCarousel from './Homecomponents/HomeCarousel';

function Home() {
    return(
        <div>
        <Topgradient/>
        <Cards/>
        <Chooseus/>
        <HomeCarousel/>
        <Testimonials/>
        
        
        </div>
    )
}
export default Home;