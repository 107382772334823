import React from "react";
import PropTypes from "prop-types";
import "./Cards.css";
import { Link, Routes, Route } from "react-router-dom";
import mbaimg from "../../../Assets/Media/MBA.jpg"
import aidsimg from "../../../Assets/Media/aids.jpg"
import ahsimg from "../../../Assets/Media/ahs.jpg"
const Cards = (props) => {
  return (
    <div className='Ccontent'>
         <div className='Chead'><h2>Our Featured Course</h2></div>
    <section class="articles">
  <article>
    <div class="article-wrapper">
      <figure>
        <img src={mbaimg} alt="" />
      </figure>
      <div class="article-body">
        <h2>MBA/PGDM</h2>
        <p className="article-p">
        <br /><br />MBA and PGDM provide advanced education in business and management. These programs focus on developing leadership and managerial skills to prepare graduates for strategic roles in various industries. These course specializations, allowing students to delve deeper into areas such as finance, marketing, human resources, or operations.
        </p>
        <Link to="/enroll" className="read-more">
          Enroll Now <span class="sr-only">about this is some title</span>
          <svg xmlns="http://www.w3.org/2000/svg" class="icon" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
          </svg>
        </Link>
        
      </div>
    </div>
  </article>
  <article>

    <div class="article-wrapper">
      <figure>
        <img src={aidsimg} alt="" />
      </figure>
      <div class="article-body">
        <h2>AIDS(AI with Data Science)</h2>
        <p className="article-p">
        Cutting-Edge Technology: This program combines artificial intelligence and data science, emphasizing the integration of advanced technologies for data analysis and decision-making. Students gain expertise in both AI and data science, acquiring a unique skill set that is increasingly valuable in the tech-driven job market.
        </p>
        <Link to="/enroll" class="read-more">
          Enroll Now <span class="sr-only">about this is some title</span>
          <svg xmlns="http://www.w3.org/2000/svg" class="icon" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
          </svg>
        </Link>
      </div>
    </div>
  </article>
  <article>

    <div class="article-wrapper">
      <figure>
        <img src={ahsimg} alt="" />
      </figure>
      <div class="article-body">
        <h2>Allied Health Science</h2>
        <p className="article-p">
        Allied health sciences encompass a range of healthcare professions that provide support to medical practitioners, including diagnostic, therapeutic, and technical roles. Students can specialize in areas like medical laboratory technology, radiography, physiotherapy, and more.
        </p>
        <Link to="/enroll" class="read-more">
          Enroll Now <span class="sr-only">about this is some title</span>
          <svg xmlns="http://www.w3.org/2000/svg" class="icon" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
          </svg>
        </Link>
      </div>
    </div>
  </article>
</section>
</div>
  );
};

Cards.propTypes = {};

export default Cards;
