import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./HomeCarousel.css";
import tp1 from "../../../Assets/TCMedia/1.jpg"
import tp2 from "../../../Assets/TCMedia/2.jpg"
import tp3 from "../../../Assets/TCMedia/3.jpg"
import tp4 from "../../../Assets/TCMedia/4.jpg"
import tp5 from "../../../Assets/TCMedia/5.jpg"
import tp6 from "../../../Assets/TCMedia/6.jpg"
import tp7 from "../../../Assets/TCMedia/7.jpg"
import tp8 from "../../../Assets/TCMedia/8.jpg"
import tp9 from "../../../Assets/TCMedia/9.jpg"
import tp10 from "../../../Assets/TCMedia/10.jpg"
import tp11 from "../../../Assets/TCMedia/11.jpg"
import tp12 from "../../../Assets/TCMedia/12.jpg"
import tp13 from "../../../Assets/TCMedia/13.jpg"
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
    slidesToSlide: 4 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 3,
    slidesToSlide: 3 // optional, default to 1.
  },

  //mobile view fix
  mobile: {
    breakpoint: { max: 767, min: 3 },
    items: 3,
    slidesToSlide: 1 // optional, default to 1.
  }
};
const sliderImagePaths = [
  {
    path: tp1,
  },
  {
    path: tp2,
  },
  {
    path: tp3,
  },
  {
    path: tp4,
  },  
  {
    path: tp5,
  },
  {
    path: tp6,
  },  
  {
    path: tp7,
  },
  {
    path: tp8,
  },  
  {
    path: tp9,
  },
  {
    path: tp10,
  },  
  {
    path: tp11,
  },
  {
    path: tp12,
  },  
  {
    path: tp13,
  },
  
  
];
// const sliderImageUrl = [
//   //First image url
//   {
//     url:
//     "https://textilevaluechain.in/wp-content/uploads/2020/06/PSG-College-logo.jpg"
//   },
//   {
//     url:
//     "https://textilevaluechain.in/wp-content/uploads/2020/06/PSG-College-logo.jpg"
//   },
//   //Second image url
//   {
//     url:
//     "https://textilevaluechain.in/wp-content/uploads/2020/06/PSG-College-logo.jpg"
//   },
//   //Third image url
//   {
//     url:
//     "https://textilevaluechain.in/wp-content/uploads/2020/06/PSG-College-logo.jpg"
//   },

//   //Fourth image url

//   {
//     url:
//     "https://textilevaluechain.in/wp-content/uploads/2020/06/PSG-College-logo.jpg"
//   }
// ];
const Slider = () => {
  return (
    <div className="hcparent">
     <div className="hch1"><h1>Our Top Colleges</h1></div>
      <Carousel
        responsive={responsive}
        autoPlay={true}
        swipeable={true}
        draggable={true}
        showDots={false}
        infinite={true}
        partialVisible={true}
        dotListClass="custom-dot-list-style"
      >
        {sliderImagePaths.map((imagePath, index) => {
          return (
            
            <div className="hcslider" key={index}>
              <img className="hcimg" src={imagePath.path} alt={`image-${index}`} />
            </div>
            
          );
        })}
      </Carousel>
    </div>
  );
};
export default Slider;
