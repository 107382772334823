import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import "./Feedback.css";
import { FaStar } from 'react-icons/fa';
import logo from '../../Assets/Media/student1.png';
import logo1 from '../../Assets/Media/student1.png';
import logo2 from '../../Assets/Media/student2.png';
import logo3 from '../../Assets/Media/student1.png';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 3,
    slidesToSlide: 3,
  },


  mobile:{
    breakpoint: { max: 767, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const Feedback = () => {
  const testimonialItems = [
    {
      img: logo,
      name: 'Karthikeyan',
      profession: 'Student',
      text: 'Their personalized guidance and expert advice helped me secure admission to my dream college. I am grateful for their unwavering support. Beyond professional services, the team is easy to catch up with and available throughout the process. I am grateful for even caring for us after the admission.',
    },
    {
      img: logo1,
      name: 'Koushik',
      profession: 'student',
      text: 'I was impressed by the personalized guidance from  LP Future Educational Services. They took the time to understand my goals and aspirations, tailoring their advice to my unique needs. Their support made a significant difference in my educational journey.',
    },
    {
      img: logo2,
      name: 'Swetha',
      profession: 'student',
      text: 'As a student navigating the challenging landscape of educational choices, I found LP FUTURE to be an invaluable resource. The team took the time to understand my unique needs, goals, and aspirations, offering tailored advice that made my decision-making process much more informed',
    },
    {
      img: logo2,
      name: 'Keerthana',
      profession: 'student',
      text: 'LP FUTURE has been a game-changer in my pursuit of higher education. Their emphasis on customized consultations allowed me to receive guidance that aligned perfectly with my ambitions. The array of educational options they presented allowed to make a well-informed decision.',
    },
  ];
  

  return (
    <div className="container">
      <div className="containerin">
        <div className="toptext" >
          
          <h1 className='toptext2'>Our Client Saying!</h1>
        </div>

        <Carousel
          responsive={responsive}
          infinite={true}
          draggable={true}
          swipeable={true}
          arrows={true}
          autoPlay={true}
          autoPlaySpeed={1500}
          keyBoardControl={true}
          containerClass="owlcarousel"
        >
          {testimonialItems.map((item, index) => (
            <div key={index} className="testimonialitem">
              <div className="flex">
                <div>
                  <center>
                  <img className="tplogo" src={item.img} alt="Client" width={100} height={100}/></center>
                </div>
                <div className="ms">
                  <h4 className="textname">{item.name}</h4>
                  <div><p className="textprof">{item.profession}</p></div>
                  <div className="textfafa">
                    {[...Array(5)].map((_, i) => (
                      <FaStar key={i} className="me-1 text-primary" />
                    ))}
                  </div>
                </div>
              </div>
             
                <p className="about">{item.text}</p>
              
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default Feedback;
