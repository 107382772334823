import React from 'react';
import './RadioBtn.css'; // Import your CSS file

const RadioBtn = ({ selectedOption, onRadioChange }) => {
  const handleOptionChange = (event) => {
    onRadioChange(event.target.value);
  };

  return (
    <div className="radio-container">
      <label className={`radio-label ${selectedOption === 'UG' ? 'selected' : ''}`}>
        <input
          type="radio"
          value="UG"
          checked={selectedOption === 'UG'}
          onChange={handleOptionChange}
        />
        UG
      </label>

      <label className={`radio-label ${selectedOption === 'PG' ? 'selected' : ''}`}>
        <input
          type="radio"
          value="PG"
          checked={selectedOption === 'PG'}
          onChange={handleOptionChange}
        />
        PG
      </label>
  
      <label className={`radio-label ${selectedOption === 'Others' ? 'selected' : ''}`}>
        <input
          type="radio"
          value="Others"
          checked={selectedOption === 'Others'}
          onChange={handleOptionChange}
        />
        Others
      </label>
    </div>
  );
};

export default RadioBtn;


/*
import React from 'react';
import './RadioBtn.css'; // Import your CSS file

const RadioBtn = ({ selectedOption, onRadioChange }) => {
  const handleOptionChange = (event) => {
    onRadioChange(event.target.value);
  };

  return (
    <div className="radio-container">
      <label className={selectedOption === 'UG' ? 'selected' : ''}>
        <input
          type="radio"
          value="UG"
          checked={selectedOption === 'UG'}
          onChange={handleOptionChange}
        />
        UG
      </label>

      <label className={selectedOption === 'PG' ? 'selected' : ''}>
        <input
          type="radio"
          value="PG"
          checked={selectedOption === 'PG'}
          onChange={handleOptionChange}
        />
        PG
      </label>
    </div>
  );
};

export default RadioBtn;

*/ 