import React from 'react'
import "./chooseus.css"
function chooseus() {
  return(
   <div className='chooseuscontent'>
   <div className='chooseush1'><h1>Why Choose US?</h1></div>
   <div className='chooseusp'>
   <p><strong>LP FUTURE</strong> is a leading education and management advisor startup. We believe
that one size does not fit all. Our consultations are tailored to your specific needs, goals, and aspirations.
<br></br> <br></br>
We also have wider range of options for students to select their field of education,By this way, they’ll be able to choose the best one for them.</p></div>
   </div> 
  )
}

export default chooseus