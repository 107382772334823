import React, { useState, useEffect } from "react";
import "./AllCoures.css";
import Section1 from "./ACComponents/Section1";
import CourseList from "./ACComponents/CourseList";
import HomeCarousel from "../Home/Homecomponents/HomeCarousel";

function AllCourses() {
  const [showLoadingModal, setShowLoadingModal] = useState(true);
  const [timeLeft, setTimeLeft] = useState(5); // Change the initial time here (in seconds)

  useEffect(() => {
    const loadingTimeout = setTimeout(() => {
      setShowLoadingModal(false);
    }, 5000);

    const timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => {
      clearTimeout(loadingTimeout);
      clearInterval(timer);
    };
  }, []);

  return (
    <div>
      {showLoadingModal && (
        <div className="loading-modal">
          <div className="modal-content">
            <p>Please wait for 5-10 seconds, Our Latest Updated Course is Loading...</p>
            <div className="timer">{timeLeft} sec</div>
            <button onClick={() => setShowLoadingModal(false)}>Close</button>
          </div>
        </div>
      )}
      <Section1 />
      <CourseList />
      <HomeCarousel />
    </div>
  );
}

export default AllCourses;